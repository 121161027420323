import { fetchCMSForSSR, useCMS, UseCMSOptions, UseCMSResult } from "./useCMS";

export type UseCMSPartner = {
  _id: string;
  name?: string;
  slug?: string;
  description?: string;
  image?: string;
  cover?: string;
  regionTags?: string[];
  solutionTags?: string[];
  partnerTypeTags?: string[];
};

interface UseCMSPartnersResult<T> extends UseCMSResult<T> {
  partners?: T[];
}

export const useCMSPartners = (
  options: UseCMSOptions,
  ready?: boolean
): UseCMSPartnersResult<UseCMSPartner> => {
  const { data, loading, page, pages } = useCMS<UseCMSPartner>({
    ...options,
    endpoint: "partner/filter",
    ready,
  });

  return {
    partners: data,
    loading,
    page,
    pages,
  };
};

export const fetchCMSPartnersSSR = async () =>
  await fetchCMSForSSR({ endpoint: "partner/filter" });
