import { UseCMSPartner, useCMSPartners } from "./cms/useCMSPartners";

const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

export const getLicensorImage = (
  partners: UseCMSPartner[],
  name?: string,
  abbreviation?: string
) => {
  const licensor = partners.find((partner) => {
    const namesAreEqual =
      partner?.name?.toLowerCase() === name?.toLocaleLowerCase();
    const nameSlugsAreEqual =
      slugify(partner?.name || "") === slugify(name || "");
    const abbreviationIsEqual =
      partner?.name?.toLocaleLowerCase() === abbreviation?.toLocaleLowerCase();
    const abbreviationSlugsAreEqual =
      slugify(partner?.name || "") === slugify(abbreviation || "");
    const nameEqualToSlug =
      name?.toLocaleLowerCase() === partner?.slug?.toLocaleLowerCase();
    const abbreviationEqualToSlug =
      abbreviation?.toLocaleLowerCase() === partner?.slug?.toLocaleLowerCase();

    return (
      namesAreEqual ||
      nameSlugsAreEqual ||
      abbreviationIsEqual ||
      abbreviationSlugsAreEqual ||
      nameEqualToSlug ||
      abbreviationEqualToSlug
    );
  });

  return licensor;
};
export const useLicensorImage = (name?: string, abbreviation?: string) => {
  const licensors = useCMSPartners({}, true);
  return getLicensorImage(licensors?.partners || [], name, abbreviation);
};

export default useLicensorImage;
